body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 20px;
  background-color: #f0f0f0;
  font-family: iransans !important;
}
@font-face {
  font-family: "iransans";
  src: local("iransans"),
   url("./fonts/iransans.ttf") format("truetype");
  
 }
h1 {
  color: #333;
}

form {
  margin-bottom: 20px;
}

input, select, button {
  margin-right: 10px;
  padding: 5px;
}
