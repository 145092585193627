.catList{
    
    gap: 10px;
    margin-top: 10px;
    column-count: 4;
    column-fill: balance;
}
.each{
    margin-bottom: 10px;
    text-align: center;
  padding: 10px;
  border: 4px solid black;
  border-radius: 10px;
  background: linear-gradient(90deg, rgb(0, 183, 6) 0%,rgb(0, 70, 50) 100%);
  
}
.each a{
    color: black;
    font-size: 15px;
    text-decoration: none;
}
.liStyle{
    background: rgba(255, 255, 255, 0.34);
  margin: 10px;
  padding: 10px;
  border: 2px solid black;
  border-radius: 10px;
}